<template>
  <div class="mod-employee">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="keyword">
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          placeholder="按部门筛选"
          @visible-change="departmentSelect"
          @change="departmentSelectChange"
          name="departmen"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="tid">
        <el-select
          v-model="dataForm.tid"
          clearable
          placeholder="按班组筛选"
          @visible-change="teamSelect"
        >
          <el-option
            v-for="item in teamOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="dataForm.status"
          clearable
          filterable
          placeholder="按状态筛选"
        >
          <el-option
            v-for="item in statusOpt"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <!-- <el-button @click="resetForm()" type="info">重置</el-button> -->
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button type="info" @click="openBatchImportPopUp"
          >批量导入</el-button
        >
      </el-form-item>
    </el-form>

    <el-table border :data="dataList">
      <el-table-column
        label="姓名"
        prop="name"
        align="center"
        width="160"
      ></el-table-column>
      <el-table-column
        label="性别"
        prop="sex"
        align="center"
        width="70"
      ></el-table-column>
      <el-table-column
        label="电话"
        prop="tel"
        align="center"
        width="130"
      ></el-table-column>

      <el-table-column
        label="部门"
        prop="departmentName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="班组"
        prop="tempName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="角色"
        prop="roleName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="岗位"
        prop="gangwei"
        align="center"
      ></el-table-column>
      <el-table-column
        label="入职时间"
        prop="ruzhiDate"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.ruzhiDate ? scope.row.ruzhiDate.split(" ")[0] : "" }}
        </template>
      </el-table-column>
      <el-table-column
        label="资质名称"
        prop="zizhiName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="资质证号"
        prop="zizhiNumber"
        align="center"
      ></el-table-column>
      <el-table-column
        label="资质有效期"
        prop="zizhiValidityDate"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          {{
            scope.row.zizhiValidityDate
              ? scope.row.zizhiValidityDate.split(" ")[0]
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">入职申请审批中</span>
          <span v-if="scope.row.status == 1">在职</span>
          <span v-if="scope.row.status == 2">已拒绝入职</span>
          <span v-if="scope.row.status == 3">修改员工信息审批中</span>
          <span v-if="scope.row.status == 4">离职审批中</span>
          <span v-if="scope.row.status == 5">已离职</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="240" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 0"
            type="primary"
            size="mini"
            plain
            @click="approvalHandler(scope.row.id)"
            >入职审批</el-button
          >
          <el-button
            v-if="scope.row.status == 3"
            type="primary"
            size="mini"
            plain
            @click="updateApprovalHandler(scope.row.id)"
            >修改审批</el-button
          >
          <el-button
            v-if="scope.row.status == 4"
            type="primary"
            size="mini"
            plain
            @click="lizhiApprovalHandler(scope.row.id)"
            >离职审批</el-button
          >
          <el-button
            type="primary"
            size="mini"
            plain
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="
              scope.row.status != 0 &&
              scope.row.status != 2 &&
              scope.row.status != 5 &&
              scope.row.status != 4
            "
            type="danger"
            size="mini"
            plain
            @click="quitEmployee(scope.row.id)"
            >离职</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.page"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="dataForm.limit"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <!-- 自定义组件 -->
    <template>
      <employee-add-or-update
        ref="EmAddOrUpdDom"
        v-if="EmAddOrUpd_visible"
        @refreshDataList="getDataList"
      />

      <Examine
        ref="ExamingDom"
        v-if="examing_visible"
        @refreshDataList="getDataList"
      />

      <!-- 批量新增组件 -->
      <batch-import
        ref="BImportDom"
        v-if="batchImport_visible"
        @refreshDataList="getDataList"
      ></batch-import>
    </template>

    <el-dialog title="提示" :visible.sync="quitDialogVisible" width="30%">
      <el-date-picker
        v-model="quitDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="请选择离职日期"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quitDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteEmployeeHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import EmployeeAddOrUpdate from "./components/employee-add-or-update.vue";
import Examine from "./components/examine.vue";
import BatchImport from "./components/batch-import.vue";

export default {
  components: {
    EmployeeAddOrUpdate,
    Examine,
    BatchImport,
  },
  data() {
    return {
      quitDate: "",
      quitDialogVisible: false,
      EmAddOrUpd_visible: false,
      examing_visible: false,
      batchImport_visible: false, //  批量上传弹出框
      eid: "",

      dataForm: {
        did: "",
        tid: "",
        keyword: "",
        limit: 10,
        page: 1,
        status: "",
      },
      totalCount: 0,
      dataList: [],

      dataForm: {
        keyword: "",
        did: "",
        tid: "",
      },
      departmentOptions: [], //部门列表选项
      teamOptions: [], //部门列表选项

      statusOpt: [
        { name: "入职审批", value: 0 },
        { name: "在职", value: 1 },
        { name: "离职审批", value: 2 },
        { name: "已离职", value: 3 },
      ],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    quitEmployee(eid) {
      this.eid = eid;
      this.quitDialogVisible = true;
    },
    // 重置表单
    resetForm() {
      if (this.$refs["DataForm"]) {
        this.$refs["DataForm"].resetFields();
      }
    },
    // 部门选择(打开下拉框)
    departmentSelect(e) {
      if (e) {
        this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
          this.departmentOptions = res?.data?.list;
        });
      }
    },
    // 部门选择(选择发生变化)
    departmentSelectChange(e) {
      this.dataForm.tid = "";
      this.teamOptions = [];
    },
    // 班组选择(打开下拉框)
    teamSelect(e) {
      if (e) {
        this.$http.team
          .list({ did: this.dataForm.did, limit: 99999, page: 1 })
          .then((res) => {
            this.teamOptions = res?.data?.list;
          });
      }
    },

    // 离职审批
    lizhiApprovalHandler(eid) {
      this.$confirm("是否同意该员工离职", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(() => {
          // 同意离职
          this.$http.employee.quit({ eid: eid, flag: 0 }).then((res) => {
            if (res && res.code == 0) {
              this.$message.success(res.msg);
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((action) => {
          if (action == "cancel") {
            // 拒绝离职
            this.$http.employee.quit({ eid: eid, flag: 1 }).then((res) => {
              if (res && res.code == 0) {
                this.$message.success(res.msg);
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        });
    },
    // 信息修改审批
    updateApprovalHandler(eid) {
      this.examing_visible = true;
      this.$nextTick(() => {
        this.$refs["ExamingDom"].init(eid);
      });
      return;
      this.$confirm("是否同意该员工信息修改", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(() => {
          // 同意修改
          this.$http.employee
            .approvalUpdate({ eid: eid, flag: 0 })
            .then((res) => {
              if (res && res.code == 0) {
                this.$message.success(res.msg);
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch((action) => {
          if (action == "cancel") {
            // 拒绝修改
            this.$http.employee
              .approvalUpdate({ eid: eid, flag: 1 })
              .then((res) => {
                if (res && res.code == 0) {
                  this.$message.success(res.msg);
                  this.getDataList();
                } else {
                  this.$message.error(res.msg);
                }
              });
          }
        });
    },
    // 入职审批
    approvalHandler(eid) {
      this.$confirm("是否同意该员工入职", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(() => {
          // 同意入职
          this.$http.employee
            .approvalRuzhi({ eid: eid, flag: 0 })
            .then((res) => {
              if (res && res.code == 0) {
                this.$message.success(res.msg);
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch((action) => {
          if (action == "cancel") {
            // 拒绝入职
            this.$http.employee
              .approvalRuzhi({ eid: eid, flag: 1 })
              .then((res) => {
                if (res && res.code == 0) {
                  this.$message.success(res.msg);
                  this.getDataList();
                } else {
                  this.$message.error(res.msg);
                }
              });
          }
        });
    },
    // 获取员工列表
    getDataList() {
      this.$http.employee.list(this.dataForm).then((res) => {
        if (res.data) {
          this.dataList = res.data.list || [];
          this.totalCount = res.data.totalCount;
        } else {
          this.dataList = [];
          this.totalCount = 0;
        }
      });
    },

    // 员工离职
    deleteEmployeeHandler() {
      this.$http.employee
        .approvalQuit({ eid: this.eid, quitDate: this.quitDate })
        .then((res) => {
          if (res && res.code == 0) {
            this.$message.success(res.msg);
            this.quitDialogVisible = false;
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 打开新增或修改员工组件
    addOrUpdateHandle(id) {
      this.EmAddOrUpd_visible = true;
      this.$nextTick(() => {
        this.$refs["EmAddOrUpdDom"].init(id);
      });
    },

    // 显示页数发生变化
    sizeChangeHandle(val) {
      this.dataForm.limit = val;
      this.dataForm.page = 1;
      this.getDataList();
    },
    // 当前页发生变化
    currentChangeHandle(val) {
      this.dataForm.page = val;
      this.getDataList();
    },
    // 打开批量新增弹窗
    openBatchImportPopUp() {
      this.batchImport_visible = true;
      this.$nextTick(() => {
        this.$refs["BImportDom"].init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.batch-import-upload) {
  display: inline-block;
  margin-left: 10px;
  .el-upload-dragger {
    display: inline;
  }
}
</style>